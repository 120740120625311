<template>
    <div class="deleteInvoiceManagement">
        <van-nav-bar
            title="编辑发票抬头"
            left-arrow
            @click-left="onClickLeft"
            fixed
            :z-index="100"
        />
        <div class="section">
            <div class="content">
                <van-form @submit="onSubmit">
                    <div class="content-text">
                        <van-cell-group inset>
                            <van-field name="radio" label="抬头类型" input-align="right" :rules="[{ required: true, message: '请选择抬头类型' }]">
                                <template #input >
                                    <van-radio-group v-model="list.invoiceType" direction="horizontal" >
                                        <van-radio name="1">个人</van-radio>
                                        <van-radio name="2">企业</van-radio>
                                    </van-radio-group>
                                </template>
                            </van-field>
                            <van-field
                                input-align="right"
                                v-model="list.invoice"
                                name="发票抬头"
                                label="发票抬头"
                                placeholder="请输入发票抬头"
                                :rules="[{ required: true}]"
                            />
                            <van-field
                                v-if="list.invoiceType == 2"
                                input-align="right"
                                v-model="list.taxPayer"
                                name="单位税号"
                                label="单位税号"
                                placeholder="请输入单位税号"
                                :rules="[{ required: true}]"
                            />
                            <van-field
                                input-align="right"
                                v-model="list.receiverName"
                                name="收件人"
                                label="收件人"
                                placeholder="请输入收件人"
                                :rules="[{ required: true}]"
                            />
                            <van-field
                                input-align="right"
                                v-model="list.receiverMobile"
                                :maxlength="11"
                                name="收件人电话"
                                label="收件人电话"
                                placeholder="请输入收件人电话"
                                :rules="[{ required: true}]"
                            />
                            <van-field
                                input-align="right"
                                v-model="list.receiverEmail"
                                name="收件人邮箱"
                                label="收件人邮箱"
                                placeholder="请输入收件人邮箱"
                                :rules="[{ required: true}]"
                            />
                            <van-field name="radio" label="设置默认" input-align="right">
                                <template #input >
                                    <van-radio-group v-model="list.isDefult" direction="horizontal" >
                                        <van-radio name="0">取消默认</van-radio>
                                        <van-radio name="1">默认</van-radio>
                                        
                                    </van-radio-group>
                                </template>
                            </van-field>
                        </van-cell-group>
                        
                        <div class="submitBtn">
                            <van-button round block  @click="deleteBtn" native-type="button" class="delete">删除</van-button>
                            <van-button round block type="danger" native-type="submit">提交</van-button>
                        </div>
                        
                    </div>
                </van-form>
                <!-- 弹窗 -->
                <van-popup
                    v-model="showpPolicy"
                    :overlay-style="{ backgroundColor: 'rgba(0,0,0,0.3)' }"
                    :close-on-click-overlay="false"
                    class="popupContent"
                    >
                 
                    <div class="popupContent-div">
                        
                        <p class="text-p">
                        <span class='text-v'>确定要删除吗</span>
                        </p>
                    </div>

                   
                    <div class="confirm-toUpdate">
                        <van-button  class="confirm-btn-toUpdate" @click="cancel_Btn">取消</van-button>
                        <van-button  class="confirm-btn-toUpdate btn" @click="delete_Btn">删除</van-button>
                    </div>

                </van-popup>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant';
export default{
    data(){
        return{
            showpPolicy:false,
            list:{
                invoiceType: "1",//抬头类型
                invoice:"",//发票抬头
                taxPayer:"",//单位税号
                receiverName:"",//收件人
                receiverMobile:"",//收件人电话
                receiverEmail:"",//收件人邮箱
                isDefult:"0",//默认
            },
        }
    },
    created(){
        this.list = JSON.parse(this.$route.query.item)
        console.log(this.list,"this.list");
    },
    methods:{
        // 返回
        onClickLeft() {
            this.$router.push("/controlOverInvoices")
        },
        onSubmit(){
            let userId = localStorage.getItem("customerId");
            this.list = {
                    ...this.list,
                    userId:userId,
            }
            this.$api.my.insetOrUpdate({
                    accessToken: localStorage.getItem("token"),
                    invoiceUserDO: JSON.stringify(this.list),
                }).then((res) =>{
                    if (res.errno === 200) {
                        this.$toast("修改成功")
                        this.$router.push("/controlOverInvoices")
                    }else{
                        this.$toast("修改失败")
                    }
                })
        },
        // 删除
        deleteBtn(){
            this.showpPolicy = true;
        },
        delete_Btn(){
            this.$api.my.delete({
                accessToken: localStorage.getItem("token"),
                id:this.list.id,
            }).then((res) =>{
                if (res.errno === 200) {
                    this.$toast("删除成功")
                    this.$router.push("/controlOverInvoices")
                }else{
                    this.$toast("删除失败")
                }
            })
            
            
        },
        //取消按钮
        cancel_Btn(){
            this.showpPolicy = false;
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../../assets/styles/element.less";

.deleteInvoiceManagement{
    padding: 0 .3rem;
    // width: 100vw;
    height: 100vh;
    // background: #f7f7f7;

    .section{
       
        position: relative;
        top: 46px;
        height: calc(100vh - 46px);
        .content{
            width: 100%;
            position: relative;
            padding-top: .22rem /* 11/50 */;
            .content-text{
                height: 80vh;
                overflow-y: scroll;
            }
        }
    }
    .submitBtn{
        
        position:fixed;
        bottom: .2rem /* 10/50 */;
        left: 0;
        width: 91%;
        margin: 0 .32rem /* 16/50 */ .32rem /* 16/50 */ .32rem /* 16/50 */;
        display: flex;
        .delete{
            margin-right: .1rem /* 5/50 */;
            border-radius: .1rem;
        }
    }
}
::v-deep .van-cell{
          left: 0;
          right: 0;
      .van-radio-group--horizontal{  
        .van-radio--horizontal{
          margin-right: 0;
          margin-left: .24rem /* 12/50 */;
        }
      }
      .van-field__label{
        color: #333;
      }
      .van-radio__icon--checked .van-icon{
        background-color: @radio_checked_color;
        border-color: @radio_checked_color;
      }
    }
    .van-button--danger{
        background: @ordinary_btn_color;
        border-radius: .1rem;

      }
    //   弹窗
    .popupContent {
        box-sizing: border-box;
        width: 5.4rem /* 270/50 */;
        // height: 8rem;
        max-height: 8rem /* 400/50 */;
        border-radius: 0.2rem /* 10/50 */;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: .32rem /* 16/50 */;
        line-height: .4rem /* 20/50 */;
        padding-top: .36rem /* 18/50 */;
        .popupContent-div{
            // margin-bottom: .68rem /* 34/50 */;
            padding: 0rem /* 23/50 */ 0.44rem /* 22/50 */ 0.4rem /* 20/50 */;
            overflow: scroll;
            .text-v{
                display: inline-block;
                margin-top: .05rem /* 20/50 */;
                // line-height: .4rem /* 20/50 */;
            // text-indent: 2em;
            }
        }
        .confirm-toUpdate{
            width: 100%;
            font-size: 0.32rem /* 16/50 */;
            font-weight: 500;
            border-top: .02rem /* 1/50 */ solid #efefef;
            .confirm-btn-toUpdate{
                font-family: PingFang SC;
                height: .88rem /* 44/50 */;
                line-height:.88rem /* 44/50 */;
                width: 50%;
                border-top: none;
            }
            .btn{
                color: @ordinary_btn_color;
            }
        }
    }
    .van-cell-group{
        border-radius: .1rem;
        overflow: hidden;
        box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05); 
    }

   
</style>